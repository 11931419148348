import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import '../img/icons/fontawesome'
import { Helmet } from 'react-helmet'

import Layout from '../components/Layout'
import { Container, Row, Col, Heading, Text, Image } from '../components/UI'
import AboutDescription from '../components/AboutDescription';

export const AboutPageTemplate = ({
  hero,
  experience,
  about
}) => (
  <>
    <Helmet>
      <title>Om mig - SecSafeConsult</title>
      <meta name="description" content="Læs om mine kompetencer og mere end 20 års erfaring indenfor sikkerhed." />
      <meta property="og:title" content="Om mig - SecSafeConsult" />
      <meta property="og:url" content="/about" />
    </Helmet>
    {/* Hero */}
    <Container maxWidth="content" mx="auto">
      <Container mt={["0", "xxxl"]} mx={["0", "xxxl"]}>
        <Row>
          <Col md={5} p="xl">
            <Container mt={["0", "0", "0", "xxxxl"]}>
              <Heading highlight>{hero.title}</Heading>
              <Text fontSize="xl">{hero.subtitle}</Text>
            </Container>
          </Col>
          <Col md={7} p="xl">
            <Container mx={["xxxl", "xxxl", "xxxxl", "section"]}>
              <Image src={!!hero.heroimage.image.childImageSharp ? hero.heroimage.image.childImageSharp.fluid.src : hero.heroimage.image} alt={hero.heroimage.alt} borderRadius="circle" height="auto" width="100%" />
              <Text textAlign="center" mt="lg" fontStyle="italic">{hero.imagetext}</Text>
            </Container>
          </Col>
        </Row>
      </Container>
    </Container>

    {/* ExperienceInNumbers */}
    <Container backgroundColor="bg.1" mt="subsection">
      <Container py="xxxl" maxWidth="content" mx="auto" textALign="center">
        <Row>
          {experience.blurbs.map((item, index) => (
            <Col sm={3} p="0" px={["xl", "xl", "xl", "xxxxl"]} key={index}>
              <Text fontSize="xxxl" fontWeight="bold" mb="0" color="text.1">{item.years}</Text>
              <Text fontWeight="bold">{item.text}</Text>
            </Col>
          ))}
        </Row>
      </Container>
    </Container>

    {/* AboutDescription */}
    <AboutDescription descriptions={about} />
  </>
)

AboutPageTemplate.propTypes = {
  hero: PropTypes.object,
  experience: PropTypes.object,
  about: PropTypes.array
}

const AboutPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <AboutPageTemplate
        hero={frontmatter.hero}
        experience={frontmatter.experience}
        about={frontmatter.about}
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "about-page" } }) {
      frontmatter {
        hero {
          title
          subtitle
          heroimage {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          imagetext
        }
        experience {
          blurbs {
            years
            text
          }
        }
        about {
          heading
          text
        }
      }
    }
  }
`
