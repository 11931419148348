import React from 'react';
import { Container, Row, Col, Text, Heading } from './UI';

function AboutDescription(props) {
  const { descriptions } = props;

  return (
    <Container maxWidth="content" mx="auto" my="subsection">
      <Row>
        {descriptions && descriptions.map((item, index) => (
          <Col sm={12 / descriptions.length} p="0" px={["xl", "xl", "xl", "xxxxl"]} key={index}>
            <Heading type="h3" mt="md" highlight>{item.heading}</Heading>
            <Text>{item.text}</Text>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default AboutDescription;